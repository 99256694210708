<template>
  <div class="tile is-parent">
    <article class="tile is-child box">
      <div class="content">
        <h3 class="title is-size-3">Les ventes hebdomadaires</h3>
        <table class="table">
          <thead>
            <tr>
              <th>
                <abbr title="Semaine débutant le">Semaine</abbr>
              </th>
              <th>Chiffre d'affaire (HT en €)</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>
                <button @click="prevPage" v-if="pageNumber>0">&larr;</button>
              </th>
              <th>
                <button @click="nextPage" v-if="pageNumber<pageCount-1">&rarr;</button>
              </th>
            </tr>
          </tfoot>

          <tbody>
            <tr v-for="sale in paginatedData" v-bind:key="sale._id">
              <th>{{ getDateOfWeek(sale._id.substring(6),sale._id.substring(0, 4)) }}</th>
              <th>{{ Math.round(sale.sales) }}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</template>

<script>
import salesService from '@/services/salesServices'

export default {
  name: "WeeklySales",
  data: function() {
    return {
      sales: [],
      loadingAPI: true,
      errorAPI: false,
      mesinfos: "",
      pageNumber: 0,
      size: 3
    }
  },
  mounted() {
    this.$http
      this.init()
  },
  computed: {
    filteredSales() {
      return this.sales.slice().sort((a, b) => b._id.localeCompare(a._id));
    },
    pageCount() {
      let l = this.filteredSales.length,
        s = this.size;
      return Math.ceil(l / s);
    },
    paginatedData() {
      const start = this.pageNumber * this.size,
        end = start + this.size;
      return this.filteredSales.slice(start, end);
    }
  },
  methods: {
    // Récupérer les services d'un user dont l'état est open
    init: function() {
      salesService.fetchWeeklySales()
        .then(response => (this.sales = response.data.weeklySales))
        .catch(() => {
          //console.log(error);
          this.errorAPI = true;
        });
    },
    nextPage() {
      this.pageNumber++;
    },
    prevPage() {
      this.pageNumber--;
    },
    getDateOfWeek(w, y) {
      var d = 1 + (w - 1) * 7; // 1st of January + 7 days for each week

      const maDate = new Date(y, 0, d);
      var dd = maDate.getDate();
      var mm = maDate.getMonth() + 1;
      var yyyy = maDate.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      return dd + "/" + mm + "/" + yyyy;
    }
  }
};
</script>

<style lang="css" scoped>
.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.coordinateur {
  font-family: "Open Sans", serif;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}
</style>
